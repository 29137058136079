const dynamicTextElement = document.getElementById("dynamicText");
const phrases = ["Tecnología", "Eficiencia", "Innovación"];
let currentPhraseIndex = 0;
let currentLetterIndex = 0;
let isDeleting = false;
let typingSpeed = 150;

function type() {
    const currentPhrase = phrases[currentPhraseIndex];
    if (!isDeleting && currentLetterIndex < currentPhrase.length) {
        dynamicTextElement.textContent +=
            currentPhrase.charAt(currentLetterIndex);
        currentLetterIndex++;
        setTimeout(type, typingSpeed);
    } else if (isDeleting && currentLetterIndex > 0) {
        dynamicTextElement.textContent = currentPhrase.substring(
            0,
            currentLetterIndex - 1
        );
        currentLetterIndex--;
        setTimeout(type, typingSpeed / 2);
    } else if (!isDeleting && currentLetterIndex === currentPhrase.length) {
        isDeleting = true;
        setTimeout(type, 1000); // Pausa antes de empezar a borrar
    } else if (isDeleting && currentLetterIndex === 0) {
        isDeleting = false;
        currentPhraseIndex = (currentPhraseIndex + 1) % phrases.length;
        setTimeout(type, typingSpeed);
    }
}

// Función para contar números
function animateNumber(element, start, end, duration, suffix = "") {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        element.textContent =
            Math.floor(progress * (end - start) + start) + suffix;
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

// Detecta si el elemento está en pantalla
function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
}

// Inicia la animación cuando el usuario llega a la sección
function animateOnScroll() {
    const counters = document.querySelectorAll(".count");
    counters.forEach((counter) => {
        if (isInViewport(counter) && !counter.classList.contains("animated")) {
            const target = +counter.getAttribute("data-target");
            const suffix = counter.getAttribute("data-suffix") || ""; // Sufijo como '+' o '%'
            animateNumber(counter, 0, target, 2000, suffix); // 2 segundos para el conteo
            counter.classList.add("animated");
        }
    });
}
function observeImages() {
    const images = document.querySelectorAll("#app-img-1, #app-img-2");
    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    console.log(
                        `La imagen ${entry.target.id} está en el viewport`
                    );
                    entry.target.classList.add("app-img-visible"); // Agrega clase cuando sea visible
                    observer.unobserve(entry.target); // Deja de observar después de la animación
                }
            });
        },
        { threshold: 0.1 }
    ); // Inicia animación cuando esté un 10% visible
    images.forEach((img) => observer.observe(img));
}

// Función para observar y animar los íconos cuando entran en el viewport
function animateCards() {
    const cards = document.querySelectorAll(".card");

    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("card-visible"); // Añadir clase para activar animación
                    observer.unobserve(entry.target); // Dejar de observar después de animar
                }
            });
        },
        { threshold: 0.1 }
    ); // El 10% del elemento debe ser visible para activar la animación

    cards.forEach((card) => {
        observer.observe(card); // Inicia la observación de cada tarjeta
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Iniciar la animación de escritura del texto dinámico
    setTimeout(type, typingSpeed);
    // Escuchar el evento de desplazamiento para los contadores
    window.addEventListener("scroll", animateOnScroll);
    // Iniciar el observador de imágenes
    observeImages();

    animateCards();




});
